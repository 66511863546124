var createIpValidator = require('is-my-ip-valid')

var reEmailWhitespace = /\s/
var reHostnameFirstPass = /^[a-zA-Z0-9.-]+$/
var reHostnamePart = /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])$/
var rePhoneFirstPass = /^\+[0-9][0-9 ]{5,27}[0-9]$/
var rePhoneDoubleSpace = / {2}/
var rePhoneGlobalSpace = / /g

exports['date-time'] = /^\d{4}-(?:0[0-9]{1}|1[0-2]{1})-[0-9]{2}[tT ]\d{2}:\d{2}:\d{2}(?:\.\d+|)([zZ]|[+-]\d{2}:\d{2})$/
exports['date'] = /^\d{4}-(?:0[0-9]{1}|1[0-2]{1})-[0-9]{2}$/
exports['time'] = /^\d{2}:\d{2}:\d{2}$/
exports['email'] = function (input) { return (input.indexOf('@') !== -1) && (!reEmailWhitespace.test(input)) }
exports['ip-address'] = exports['ipv4'] = createIpValidator({ version: 4 })
exports['ipv6'] = createIpValidator({ version: 6 })
exports['uri'] = /^[a-zA-Z][a-zA-Z0-9+-.]*:[^\s]*$/
exports['color'] = /(#?([0-9A-Fa-f]{3,6})\b)|(aqua)|(black)|(blue)|(fuchsia)|(gray)|(green)|(lime)|(maroon)|(navy)|(olive)|(orange)|(purple)|(red)|(silver)|(teal)|(white)|(yellow)|(rgb\(\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*\))|(rgb\(\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*\))/
exports['hostname'] = function (input) {
  if (!(reHostnameFirstPass.test(input))) return false

  var parts = input.split('.')

  for (var i = 0; i < parts.length; i++) {
    if (!(reHostnamePart.test(parts[i]))) return false
  }

  return true
}
exports['alpha'] = /^[a-zA-Z]+$/
exports['alphanumeric'] = /^[a-zA-Z0-9]+$/
exports['style'] = /\s*(.+?):\s*([^;]+);?/g
exports['phone'] = function (input) {
  if (!(rePhoneFirstPass.test(input))) return false
  if (rePhoneDoubleSpace.test(input)) return false

  var digits = input.substring(1).replace(rePhoneGlobalSpace, '').length

  return (digits >= 7 && digits <= 15)
}
exports['utc-millisec'] = /^[0-9]{1,15}\.?[0-9]{0,15}$/
